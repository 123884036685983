<template>
  <div class="  " style="" v-if="acti.length">
    <!-- TODO: add an empty state via SLOT  -->
    <div class="box aboutBox no-shadow-over" style="background: #fff">
      <br />

      <div class="columns is-mobile" v-if="showHeader">
        <div class="column">
          <h4 class="stylizedNOOO title is-5" style="">Recent Activity</h4>
        </div>

        <div class="column is-narrow" v-if="allTypes.length > 1">
          <b-field>
            <b-select
              v-model="dropType"
              style="border-radius: 30px"
              placeholder="Featured"
              icon="filter"
              icon-pack="fal"
            >
              <option value="all">
                <span v-if="dropType == 'all'">Filter</span>
                <span v-else>All</span>
              </option>
              <option v-for="i in allTypes" :value="i" :key="i">{{ i }}</option>
            </b-select>
          </b-field>
        </div>
      </div>
      <!--
        {{ dropType }} == dropType
        <div class="block">
          <b-checkbox v-model="checkboxTypesShown" :native-value="i" v-for="i in allTypes">
            {{ i }}
          </b-checkbox>
        </div>
  -->

      <b-field v-if="isOwner">
        <b-switch type="is-info" v-model="showPrivateActivities">
          <b-icon pack="fas" icon="lock" />
          <span>Also view my private activities </span>
        </b-switch>
      </b-field>
      <div v-for="i in acti" :key="i">
        <notifItem :n="i" display="activity" v-show="shouldShow(i.type)" :isProfile="isProfile" />
      </div>

      <router-link
        :to="baseUrl + '/activity/' + dropType"
        class="actor"
        v-if="!type && dropType != 'all'"
        style="margin-top: 20px"
      >
        <b-button type="is-info" outlined rounded size="is-med">
          View more <strong>{{ dropType }} </strong> activities

          <span v-if="!globalActivityListing"> from this member</span>
        </b-button>
      </router-link>

      <router-link :to="baseUrl + '/activity'" class="actor" v-if="$route.params.type" style="margin-top: 20px">
        <b-button type="is-info" outlined icon-left="chevron-left" icon-pack="fal" size="is-med">
          All activities
        </b-button>
      </router-link>

      <router-link :to="baseUrl + '/activity'" class="actor" v-if="!isDedicatedPage" style="margin-top: 20px">
        <b-button type="is-info" outlined icon-pack="fal" size="is-med"> All activities </b-button>
      </router-link>

      <!--
        <router-link v-if="!isOwner" :to="'/report-abuse?url=https://invitation.codes' + profile.url" class="  ">
          <b-button size="small" icon-left="exclamation-triangle" type="is-danger  is-outlined" icon-pack="fal">
            Report Abuse
          </b-button>
        </router-link>
        -->
    </div>
  </div>
</template>
<script>
/*
import VClamp from "vue-clamp"; //needs some config.vue setup...https://justineo.github.io/vue-clamp/demo/
import badge from "@/components/profile/Badge.vue";
import ProfileAvatar from "@/components/e/profileAvatar.vue";
import ProfileCardHover from "@/components/profile/ProfileCardHover.vue";
import verifiedBadge from "@/components/e/verifiedBadge.vue";
import UserText from "@/components/e/UserText.vue";
*/

import _ from "lodash";
import notifItem from "@/components/notifItem";

export default {
  name: "txt",
  components: {
    //  VClamp,
    //  badge,
    notifItem,
    /*  ProfileCardHover,
    ProfileAvatar,
    verifiedBadge,
    UserText,*/
    //  Navbar: Navbar,
  },
  props:
    "globalActivityListing baseUrl profile type showHeader nbPosts isOwner  shortid avatar website url intro desc firstName name handle ".split(
      " "
    ),
  data() {
    return {
      //output: '',
      acti: [],
      //  noti: [],
      //news: [],
      dropType: "all",
      showPrivateActivities: true,
      isProfile: true,
      // isProfile: false, //checkbox
      checkboxTypesShown: [],
      tabLevel: "public",

      //expandText: false, //should be true on server-rendered- pages.
      //referralCodesCount: 0,
      //referralCodesCountLoading: true
    };
  },
  computed: {
    allTypes: function () {
      var a = _.map(this.acti, "type");
      var b = _.map(this.noti, "type");
      var c = _.map(this.news, "type");
      console.log(a, b, c);
      var flat = _.flatten([a, b, c]);
      var all = _.uniq(flat);
      console.log(all);

      return all;
    },
    isDedicatedPage: function () {
      console.log(" RROOOO", this.$route.name, this.$route.name.startsWith("profileActi"));
      return this.$route.name.startsWith("profileActi");
    },
    opt: function () {
      //   var isDedicatedPage = this.$route.name.startsWith('provileActi');
      var limit = 20;
      if (this.isDedicatedPage) limit = 200;
      if (this.globalActivityListing) limit = 200;
      var a = {
        limit: limit, // this.isDedicatedPage ? 200 : 15 // smaller for mini box plz.

        // debug: 1
      };
      if (this.type) a.type = this.type;
      if (this.isOwner && this.showPrivateActivities) a.owner = 1;
      //  if (this.tabLevel == 'admin') a.admin = 1;

      //SWITCH HEWRE!
      return a;
    },
    loggedUserId: function () {
      return this.$store.main.getters.userId;
    },
    isAdmin: function () {
      return this.$store.main.getters.isAdmin;
    },
  },
  methods: {
    toggleText: function (event) {
      //alert('toggle;')
      this.expandText = true;
    },
    shouldShow(type) {
      if (this.dropType == "all") return true;
      return type == this.dropType; // filter
      //return this.checkboxTypesShown.length == 0 || this.checkboxTypesShown.includes(type);
    },
    fetchMain(event) {
      this.acti = this.noti = this.news = [];
      //  var loadAllGlobalActivities = this.$route.name == 'activityNow'
      if (this.globalActivityListing) {
        //global /activity. route
        // no profile passed...
        window.API.getTrendingActivities(this.opt).then((res) => {
          this.acti = res;
        });
      } else {
        //user-specefic stuff.
        window.API.getUserActivities(this.profile.handle, this.opt).then((res) => {
          this.acti = res;
        });
      }
    },
  },
  watch: {
    "$route.params.type": function (from, to) {
      console.log("43243243294999999", from, to, "🔸🔸🔸🔸🔸🔸🔸");

      this.dropType = "all";
    },
    $route: function (from, to) {
      this.fetchMain();
    },
    showPrivateActivities: function (from, to) {
      this.fetchMain();
    },
    profile: function (from, to) {
      this.fetchMain();
    },
  },
  mounted() {
    this.fetchMain();
  },
  /*mounted() {
    this.render()
  
  },*/
};
</script>
<style>
.box.about p,
.box.about h6,
.box.about h2,
.box.about h4 {
  color: #65678e !important;
}
.box.about .tag {
  background: white;
}
</style>
