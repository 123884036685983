<template>
  <div>
    <!--
      <div class="columns">
        <div class="column"></div>
        <div class="column"></div>
        </div>
  -->
    <div class="spacer m" />
    <titleBar
      v-if="profile && profile.url"
      :title="profile.name || profile.handle"
      :headerTitleLink="profile?.url"
      :back="auto"
      :showBackArrowDesktop="true"
      :img="this.profile.avatar"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="true"
      actionButtonStyle="small"
      actionButtonRoundBg="transparent"
      iconPack="fas"
      :actions="[
        {
          icon: 'bell',
          to: '/notifications',
        },
      ]"
    />
    <div class="spacer l" />
    <!--
        {{ this.$route.params.type }} == this.$route.params.type
        -->
    <div v-if="type">
      <h4 class="stylizedNOOO title is-5" style="">
        {{ title }}
      </h4>

      <router-link :to="this.profile.url + '/activity'" class="actor" v-if="dropType != 'all'" style="margin-top: 20px">
        <b-button type="is-info" outlined icon-left="chevron-left" icon-pack="fal" size="is-med">
          All activities
        </b-button>
      </router-link>
    </div>
    <ProfileActivityStream
      :type="$route.params.type"
      :baseUrl="profile.url"
      :profile="profile"
      :showHeader="$route.params.type ? false : true"
      v-if="profile && profile.handle"
      :isOwner="isOwner"
    />
  </div>
</template>

<script>
/*
  import InvitationModule  from '../../lib/invitation-module/module.js' ;
  // InvitationModule = require('./../lib/invitation-module/module.js');
  //any ways to make it available everywhere?
  var server = InvitationModule.client;
  
  window.a = server;
  window.api = server;
  window.e = InvitationModule.endpoints;
  
  
  */
/*
import Layout from "@/components/layout/LayoutModal.vue";
import UserText from "@/components/e/UserText.vue";
import numAbv from "@/components/e/numAbv.vue";
import followersCount from "@/components/e/followersCount.vue";
import invitedUsersCount from "@/components/e/invitedUsersCount.vue";

import followButton from "@/components/e/followButton.vue";
import ProfileCardHover from "@/components/profile/ProfileCardHover.vue";
import empty from "@/components/e/empty.vue";
//import QrcodeVue from 'qrcode.vue';*/

import ProfileActivityStream from "@/components/ProfileActivityStream.vue";

import _ from "lodash";
//var tt = ExtractASIN('http://www.amazon.com/Kindle-Wireless-Reading-Display-Generation/dp/B0015T963C/ref=amb_link_86123711_2?pf_rd_m=ATVPDKIKX0DER&pf_rd_s=center-1&pf_rd_r=0AY9N5GXRYHCADJP5P0V&pf_rd_t=101&pf_rd_p=500528151&pf_rd_i=507846');
//alert(tt);
//import Layout from '@/components/layout/LayoutDash.vue';
//  import Comments from '@/components/e/comments.vue';
// import NewPostMenuItems from '@/components/nav/newPostMenuItems';
//
//
const INITIAL_ENTRIES_SHOWN = 20;
var UPPER_LIMIT_TO_SHOW = 5000;
export default {
  name: "Home",
  components: {
    ProfileActivityStream,
    /*
    UserText,
    empty,
    numAbv,
    Layout,
    followersCount,
    invitedUsersCount,
    followButton,
    ProfileCardHover, //Layout,  Comments //NewPostMenuItems*/
  },
  data() {
    return {
      nbToLoad: INITIAL_ENTRIES_SHOWN,
      people: [],
      profile: {},
      loadedAll: false,
      loading: true,

      //  endpoints: InvitationModule.endpoints
    };
  },

  mounted() {
    this.loadMain();
  },
  updated() {
    //fire on route change - all of em!
    //this.loadPost()
  },
  methods: {
    loadAll(event) {
      this.nbToLoad = this.nbToLoad * 3; //goes faster to load all...
      this.loadMain();
      // alert(4)
    },
    loadMain(e) {
      var me = this;
      //this.threads = [];
      this.loading = true;
      //if(!this.nb) nb = 3; //DEFAULT NB of entries shown

      //profileActivity profileActivityAlt profileActivityType profileActivityTypeAlt

      console.log(this.$route, "ROOTE! ");

      //var id = this.$route.params.id;
      //alert(this.$route.name + id);
      /*
      var userIdentifier = this.$route.params.username || this.$route.params.id;
      if (this.$route.params.username) {
        userIdentifier = "@" + userIdentifier; //so the back end know it's an handle...
      }*/
      var id = this.$route.params.userId || this.$store.main.getters.userId;
      var opt = { owner: !this.$store.main.getters.userId };
      window.API.userProfile(id, opt).then((res) => {
        console.log(res);
        this.profile = res;
        this.loadingProfile = false;
      });
    },
    infiniteHandler($state) {
      //alert(222)
      //  return false;
      var me = this;
      var oldCount = this.people.length || 0;
      this.nbToLoad = this.nbToLoad += 50;
      var userIdentifier = this.$route.params.username || this.$route.params.id;
      if (this.$route.params.username) {
        userIdentifier = "@" + userIdentifier; //so the back end know it's an handle...
      }

      /*
        API[apiMethod](userIdentifier, { limit: this.nbToLoad }).then(res => {
          //...
          // console.log('UgetFollowers: ', res.data);
          me.loading = false;
          this.people = res.data || [];
          if (this.people.length == oldCount || this.nbToLoad > UPPER_LIMIT_TO_SHOW) {
            //no change to count
            $state.complete(); //finished loading all rows..
            $state.loaded();
          } else {
            $state.loaded();
          }
  
          //console.log(this.threads[0], 'DEBUG THREAD')
        });
  
  */

      //this.loadAll();
    },
  },
  watch: {
    $route: function (from, to) {
      this.people = [];
      //  this.referrals= []; //prevent code from sticking to screen on profil swap
      this.loadMain();
    },
  },
  computed: {
    // NOTIF TYPE OR "activity" //
    type: function () {
      return this.$route.params.type;
    },
    title: function () {
      if (this.type) return this.type + " activity";
      return "Activity";
    },
    qrCodeData: function () {
      return "https://invitation.codes" + this.profile.url;
    },
    peopleAr: function () {
      return this.people;
    }, // useless?
    isFollowing: function () {
      return this.$route.name == "profileFollowing" || this.$route.name == "profileFollowingAlt";
    },
    isFollower: function () {
      return this.$route.name == "profileFollowers" || this.$route.name == "profileFollowersAlt";
    },
    isInvited: function () {
      return this.$route.name == "profileInvited";
    },
    id: function () {
      return this.$route.params.id;
    },
    isOwner: function () {
      return this.$store.main.getters.userId == this.profile.handle;
    },
  },
};
</script>
<style scoped>
.qr {
  background: linear-gradient(139deg, #42ffe5, #55ff2b, #e4ff00);
  background: linear-gradient(139deg, #ffed6a, #b4ff00, #fcff50);
  width: 300px;
  text-align: center;
  border: 1px solid black;
  padding: 30px;
  margin: auto;
  display: block;
  margin-top: 10vh;
}
.qr > div {
  mix-blend-mode: multiply;
  display: block;
  margin: auto;
  width: 200px;
  width: 200px;
}
.qr canvas {
  width: 200px;
  width: 200px;
}
</style>
