<template>
  <div
    v-bind:class="{
      fresh: n.fresh || n.isFresh,
      noti: display == 'notification',
      activity: display == 'activity',
      newsfeed: display == 'newsfeed',
      muted: n.isActor, //TODOOO
      wrong: false,
    }"
    class="box"
  >
    <!--
      {{ n.isFresh }}
      -->
    <r-link :to="n.urlNotif" :noLink="!useSingleLink">
      <div :class="useSingleLink ? 'bigClick enabled' : 'bigClick'">
        <r-link :to="actorUrl" :noLink="useSingleLink" class=" ">
          <div class="imgWrap">
            <img :src="mainImg" alt="" class="img" :class="'type-' + n.actorType" loading="lazy" />
            <div class="bubble" :class="n.icon">
              <b-icon pack="fas" :icon="n.icon || 'link'" />
            </div>
          </div>
        </r-link>

        <div class="circlePlus" v-if="secondaryImg && n.aggregatedActivitiesCount && n.aggOn == 'actor'">
          +{{ n.aggregatedActivitiesCount }}
        </div>

        <r-link :to="itemUrl" class="item" :noLink="useSingleLink">
          <div class="imgWrap secondary" v-if="secondaryImg">
            <img :src="secondaryImg" alt="" class="img" :class="'type-' + n.itemType" loading="lazy" />
          </div>
        </r-link>

        <p>
          <r-link :to="actorUrl" :noLink="useSingleLink" class="actor">
            {{ actorName }}
          </r-link>

          <span v-if="n.aggregatedActivitiesCount && n.aggOn == 'item'">
            and
            <r-link :to="aggUrl" class="item" :noLink="useSingleLink"> {{ aggregateStrSuffix }} </r-link>
          </span>

          <!--
        <span v-if="n.aggregatedActivitiesCount && n.aggOn == 'item'"> {{ aggregateStrSuffix }} </span>
    
        {{ n.actorId }}
            -- {{ n.icon }}
  -->
          {{ verb }}

          <r-link :to="itemUrl" class="item" :noLink="useSingleLink"> {{ itemName }} </r-link>
          <span v-if="n.aggregatedActivitiesCount && n.aggOn == 'actor'">
            and
            <r-link :to="aggUrl" class="item" :noLink="useSingleLink"> {{ aggregateStrSuffix }} </r-link>
            {{ n.itemType }}
          </span>
        </p>
        <small style="color: blue"> <timeago :datetime="n.date" :auto-update="60"></timeago></small>

        <p class="">{{ n.notifMsgPlain }}</p>
        <p class="tag is-light" v-if="n.private && display == 'activity'">
          <b-icon pack="fas" icon="lock" />
          Only you can see this
        </p>
        <p class="tag is-dark" v-if="n.secret && display == 'activity'">
          <b-icon pack="fas" icon="cog" />
          Only admins can see this
        </p>

        <!--  
               <code>{{ n.urlNotif }}</code>
               
            {{ isProfile }} == isProfile
      <div>
        <r-link :to="actorUrl">
         
          <img :src="n.actorImg" class="primaryImg" :class="n.type || 'default'" loading="lazy" style="" />
        </r-link>
      </div>
  
      <div class="txt">
        <r-link :to="entityUrlV2 || entityUrl">
          <p class="">{{ notifMsgPlain }}</p>
          <small style="color:blue;"> <timeago :datetime="date" :auto-update="60"></timeago></small>
        </r-link>
      </div>
      <div class="is-hidden-mobile secondaryImg">
        <r-link :to="entityUrlV2 || entityUrl">
          <img :src="entityImg" :class="type || 'default'" loading="lazy" style="  width:70px;" />
        </r-link>
      </div>
      <div>
        <span v-if="fresh"> xx</span>
      </div>
       -->
      </div>
    </r-link>

    <jsonDebug :data="n" label=" view data  " />
  </div>
</template>
<script>
//import bottomBar from '@/components/nav/bottomBar.vue';
//import settingsTabs from '@/components/nav/settingsTabs.vue';
export default {
  //name: 'Home',
  components: {
    //bottomBar,
  },
  props: "n display isProfile".split(" "),
  data() {
    return {};
  },

  computed: {
    useSingleLink: function () {
      return this.display == "notification";
    },
    actorImg: function () {
      return this.n.actor.avatar;
    },
    itemImg: function () {
      var i = this.n.item;
      return i.iconSquareAbsolute || i.iconSquare || i.icon || i.avatar || i.icon64;
    },

    mainImg: function () {
      //  if (this.display == 'activity') return this.itemImg || this.actorImg;
      return this.actorImg;
    },

    secondaryImg: function () {
      //if (this.display == 'activity') return false;
      return this.itemImg;
    },

    actorName: function () {
      var me = this.$store.main.getters.profile.handle;
      if (me && this.n.actorId == me) return "You";
      return this.n.actor.name || this.n.actor.displayName || this.n.actor.handle;
    },
    actorUrl: function () {
      return this.n.actor.url || "/";
    },
    aggUrl: function () {
      //Works for activity aggreg. not sur for others...
      var acto = this.n.actor.url || "/";
      if (this.n.aggOn == "item") {
        return "/notifications/" + this.n.type;
      } else {
        return acto + "/activity/" + this.n.type;
      }
    },
    itemName: function () {
      var i = this.n.item;
      var me = this.$store.main.getters.profile.handle;
      if (me && this.n.itemId == me) return "you";
      return i.name || i.displayName || i.title || i.title || i.handle || i.productName;
    },
    itemUrl: function () {
      return this.n.item.url || "/";
    },
    verb: function () {
      var a = {
        likeBotPost: "liked",
        reviewBot: "wrote a review for",
        // legacy invt

        quotaReset: "You can reposts now!",
        join: "joined",
        follow: "started following",
        viewProfile: "visited",
        createBot: "created",
        editBot: "adjusted",
        startBotConvo: "talked with",

        //
        comment: "commented on",
        saveReferralProgram: "bookmarked",
        viewReferralProgram: "viewed",
        viewReferralCode: "viewed a code for",
        createReferralCode: "posted their code for",
        editReferralCode: "edited their code",
        repostReferralCode: "reposted",
        receiveBadge: "received a new badge!",
        createDiscussion: "posted",
        editDiscussion: "edited their post",
        message: "messaged",
        donate: "donated to",
        upvoteComment: "",
        upvoteDiscussion: "upvoted",
        completeOnboardingStep: "completed", //
        dailyReward: "received",
        commentDiscuss: "commented on",
        commentReferralCode: "commented on",
        commentBlog: "commented on",
        createSocial: "",
        createShop: "created a link for",
        createAmazon: "posted a product",
        editProfilePhoto: "updated their profile photo",
        editCoverPhoto: "updated their cover photo",
        affiliatePurchase: "used your link",
      };

      var verbbbb = a[this.n.type] || this.n.type + "***";

      return verbbbb;
    },
    aggregateStrSuffix: function () {
      var c = this.n.aggregatedActivitiesCount;
      if (!c) return "";
      var s = "  " + c + " other";
      if (c > 1) s += "s"; //others
      return s;
    },
  },
};
</script>

<style scoped>
.bigClick {
  display: block;
  padding: 20px;
  border-radius: 10px;
}

.bigClick.enabled:hover {
  background: rgb(238, 238, 238);
}

.box {
  margin-bottom: 5px;
  padding: 5px;
}
.noti {
  background: #ffffff;
}

.activity {
  background: #c8e7ec;
  background: #effdff;
}

.newsfeed {
  background: #c7dfcc;
}

.noti.fresh {
  background: #e3f1ff;
  border-left: 3px #1d1dff solid;
  background: white;
  border-radius: 3px;
}

.img {
  width: 55px;
  height: 55px;
  object-fit: cover;
  border-radius: 200px;
}

.imgWrap {
  position: relative;
  width: 55px;
  height: 55px;
  float: left;
  margin-right: 10px;
}
.imgWrap.secondary {
  float: right;
  margin-left: 10px;
  margin-right: 0px;
  width: 35px;
  height: 35px;
}
.imgWrap.secondary .img {
  width: 35px;
  height: 35px;
}
.circlePlus {
  background: #d3d3d3;
  width: 36px;
  height: 36px;
  float: right;
  text-align: center;
  border-radius: 200px;
  padding-top: 8px;
  font-size: 12px;
  margin-right: 0px;
  margin-left: -5px;
}
.bubble {
  width: 25px;
  height: 25px;
  object-fit: cover;
  background: rgb(255, 0, 43); /* default */
  color: white;
  border-radius: 200px;
  position: relative;
  bottom: 29px;
  left: 39px;
  font-size: 14px;
  text-align: center;
}

.item,
.actor {
  font-weight: bold;
}

img.type-user {
  background: linear-gradient(-237deg, #ffc92f, #ff185c, #f316d5, #b81ed2);
}
/* bubble colors.   - these refer to icon used the FA-cion */

.star {
  background: rgb(255, 126, 21);
}

.sparkles {
  background: rgb(255, 251, 0);
}
.user-plus {
  background: rgb(20, 16, 243);
}
.user {
  background: rgb(38, 40, 163);
}
.comments {
  background: rgb(34, 197, 62);
}
.bookmark {
  background: rgb(255, 126, 21);
}
.gift {
  background: rgb(5, 255, 172);
}
.tag {
  background: rgb(54, 54, 54);
}
.retweet {
  background: rgb(142, 21, 255);
}
.trophy {
  background: rgb(115, 255, 21);
}
.envelope {
  background: rgb(255, 0, 119);
}
.caret-up {
  background: rgb(146, 218, 98);
}
.rocket {
  background: rgb(255, 153, 0);
}
.calendar {
  background: rgb(170, 45, 160);
}
.comment {
  background: rgb(71, 71, 71);
}
.link {
  background: rgb(14, 66, 211);
}
.user {
  background: rgb(75, 75, 75);
}
.shopping-bag {
  background: rgb(107, 223, 30);
}
.bubble.box {
  background: rgb(231, 188, 44);
}

/*
  
  
   sparkles
  baby
  user-plus
  user
  comments
  bookmark
  gift
  tag
  retweet
  trophy
  envelope
  caret-up
  rocket
  calendar
  comment
  link
  shopping-bag
  box
  
  
  
  
  .primaryImg {
    border-radius: 200px;
    width: 40px;
    max-width: 40px;
    height: 40px;
    padding: 0;
  }*/
</style>
